import { AxiosResponse } from 'axios'
import axios from '../instance'
import {
	SearchOrderParams,
	SearchCODParams,
	OrderFeeParams,
	ISentDelayOrderParams,
	IUpdateSentDelayOrder,
	OrderWeightConParams,
	SearchOrderWeightContrastParams,
} from './order.type'
import { ORDER_STATUS } from '@/type/order'
import { STATUS_ORDER_LIST } from '@/constants/courier'

export const getOrderList = async (
	params: SearchOrderParams
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/admin/order-list', { ...params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getOrderCOD = async (
	params: SearchCODParams
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/admin/order-cod', { ...params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getOrderDiffDaily = async (): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/admin/order-diff-daily')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateOrderFee = async (
	params: OrderFeeParams
): Promise<AxiosResponse> => {
	try {
		const response = await axios.put('/admin/update-order-fee', params)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const createOrderWeightCon = async (
	params: OrderWeightConParams
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post(
			'/admin/order-weight-contrast',
			params
		)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const createOrderOpposeWeightCon = async (
	params: OrderWeightConParams
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post(
			'/admin/order-oppose-weight-contrast',
			params
		)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
export const getSentDelayOrder = async (params: ISentDelayOrderParams) => {
	const { data } = await axios.get('/admin/sent-delay-orders', { params })
	return data
}

export const updateSentDelayOrder = async (payload: IUpdateSentDelayOrder) => {
	const { data } = await axios.put('/admin/update-sent-delay-order', payload)
	return data
}

export const getOrderWeightContrastList = async (
	params: SearchOrderWeightContrastParams
): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/admin/order-weight-contrast', {
			params: params,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

interface IChangeStatusPayload {
	orderId: string
	status: [ORDER_STATUS.SENT, ORDER_STATUS.REJECT]
	statusTime: string | Date
}

export const updateOrderStatus = async (
	payload: IChangeStatusPayload
): Promise<AxiosResponse> => {
	try {
		const response = await axios.post('/admin/update-status-order', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface IOrderExposeParams {
	limit: number
	offset: number
	startDate?: Date,
	endDate?: Date,
	search?: string | null
	status?: string | null
}

export const getOrderOpposeList = async (
	params: IOrderExposeParams
): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/admin/order-oppose', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export enum ORDER_OPPOSE {
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
	REJECT = 'REJECT',
}

export interface IUpdateOrderExposeID {
	orderOpposeId: string
	status: ORDER_OPPOSE
}

export const updateOrderOppose = async (payload: IUpdateOrderExposeID): Promise<any> => {
	try {
		const response = await axios.post('/admin/order-oppose', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export interface TransportObject {
	rate: {
	  rate_to_UPC: number;
	  rate_to_BKKC: number;
	  rate_to_P: number;
	  default_rate_to_UPC: number,
	  default_rate_to_BKKC: number,
	  default_rate_to_P: number
	};
	codShopRate: number;
	incrementRate: number;
	incrementRatePerRange: number;
	courier: string;
	name: string;
	image: string;
	merchantShipmentFee: number;

  }

export interface AddressObject {
    provinceName: string;
    districtName: string;
    subDistrictName: string;
    provinceCode: number;
    districtCode: number;
    subDistrictCode: number;
    zipCode: number[];
  }

export interface CalculateObject{
	originProvinceCode: number | undefined,
	weight: number,
	width: number,
	height: number,
	length: number,
	shopId: string,
	destinationAddress?: AddressObject | null | undefined
  }
export const getTransportlist = async (params: CalculateObject): Promise<TransportObject[]> => {
	try {
		const response = await axios.post('/rate-calculate', { ...params })
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}
export const orderExpedite = async (orderId: string): Promise<AxiosResponse> => {
	try {
	  const { data } = await axios.put(`/admin/expedite/${orderId}`)
	  return data
	} catch (error) {
	  return Promise.reject(error)
	}
}