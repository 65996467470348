import { COURIER, courierMap } from '@/constants/courier'

export const mapCourierLinkTracking = (courier: COURIER, trackingNumber: string): string => {
	const mapCourier = courierMap[courier]

	return mapCourier?.generateLinkTrackingNumber(trackingNumber) as string
}
export const mapRejectTrackingRef = (courier: COURIER, order: {trackingNumber:string,rejectTrackingNumber:string}): string => {
	let rejectTrackingNumber = order?.rejectTrackingNumber
	if (courier === COURIER.SPX || courier === COURIER.EMS) {
		rejectTrackingNumber = order.trackingNumber
	}
	return rejectTrackingNumber
}
